// extracted by mini-css-extract-plugin
export var aboveLeft = "section-module--above-left--c289f";
export var aboveRight = "section-module--above-right--05911";
export var content = "section-module--content--8b2bb";
export var content2 = "section-module--content-2--18a74";
export var double = "section-module--double--1a239";
export var glass = "section-module--glass--dacfb";
export var heading = "section-module--heading--b5041";
export var image = "section-module--image--8d0e7";
export var image2 = "section-module--image-2--68670";
export var left1 = "section-module--left-1--bd4ae";
export var left2 = "section-module--left-2--f0be2";
export var leftAbove = "section-module--left-above--8d0c1";
export var line = "section-module--line--beb49";
export var right1 = "section-module--right-1--d8013";
export var right1Indent = "section-module--right-1-indent--03e8e";
export var right2 = "section-module--right-2--a848e";
export var section = "section-module--section--02be1";
export var title = "section-module--title--59379";