// extracted by mini-css-extract-plugin
export var arrow = "hero-module--arrow--97708";
export var contentWrapper = "hero-module--content-wrapper--8394b";
export var description = "hero-module--description--7c4f6";
export var descriptionAnimator = "hero-module--description-animator--3bb93";
export var descriptionWrapper = "hero-module--description-wrapper--9bdb4";
export var desktopTitle = "hero-module--desktop-title--835a7";
export var image = "hero-module--image--e8061";
export var imageWrapper = "hero-module--image-wrapper--bf681";
export var mobileTitle = "hero-module--mobile-title--1a8f1";
export var ready = "hero-module--ready--bda30";
export var section = "hero-module--section--5158d";
export var title = "hero-module--title--95dee";