import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import * as s from "./featured.module.css";


const FeaturedExamples = ({value}) => {
  const [active, setActive] = useState(0);
  const scroller = useRef();
  const header = useRef();

  useEffect(() => {
    if (!header.current) return;
    const size = header.current.clientHeight;
    scroller.current.style.paddingTop = `${size}px`;
    scroller.current.style.marginTop = `-${size}px`;
  })

  const scrollTo = (id) => {
    const c = scroller.current;
    const el = c.children[id];
    c.scrollTo({
      left: el.offsetLeft,
      behavior: 'smooth',
    })
  }
  
  const onPrev = () => {
    const id = Math.max(active - 1, 0);
    scrollTo(id);
  };
  const onNext = () => {
    const id = Math.min(active + 1, value.length-1);
    scrollTo(id);
  };
  
  return !value.length ? null
  : (
    <div className={s.wrapper}>

      <div className={s.header} ref={header}>
        <h3 className={s.title}>Featured examples</h3>
        <div className={s.controls}>
          <button
            className={s.prev}
            onClick={onPrev}
            aria-label={"Previous example"}
          >
            <svg viewBox="0 0 24 24"><path d="M16,4L8,12L16,20"/></svg>
          </button>
          {value.map( (val, index) => (
            <div
              key={index}
              className={`${s.point} ${active === index ? s.active : ''}`}
            />
          ))}
          <button
            className={s.next}
            onClick={onNext}
            aria-label={"Next example"}
          >
            <svg viewBox="0 0 24 24"><path d="M8,4L16,12L8,20"/></svg>
          </button>
        </div>
      </div>

      <ul className={s.examples} ref={scroller}>
        {value.map( (val, index) => (
          <Example
            key={index}
            scroller={scroller}
            onEnter={() => setActive(index)}
          >{val}</Example>
        ))}
      </ul>
    
    </div>
  )
}

const Example = ({children, onEnter, scroller}) => {
  const ref = useRef();

  useEffect(() => {
    const trigger = ScrollTrigger.create({
      scroller: scroller.current,
      trigger: ref.current,
      horizontal: true,
      start: `left 50%`,
      end: `right 50%`,
      onEnter: onEnter,
      onEnterBack: onEnter,
    })
    return () => trigger.kill()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroller])

  useEffect(() => {
    const ch = ref.current.children;
    const tl = gsap.timeline({
      scrollTrigger: {
        scroller: scroller.current,
        trigger: ref.current,
        horizontal: true,
        start: `20% 100%`,
        end: `80% 0%`,
        scrub: true,
      },
      defaults: {
        ease: 'linear'
      }
    })
      .set(ch, {opacity: 0, rotateY: -30, immediateRender: true})
      .to(ch, {opacity: 1, rotateY: 0})
      .to(ch, {opacity: 0, rotateY: 30})
    return () => tl.kill()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroller])

  return (
    <li ref={ref}
    ><p>{children}</p></li>
  )
}

export default FeaturedExamples