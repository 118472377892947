import React, { useEffect, useRef, useState } from "react";
import PortableText from "../../portableText/portableText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import getColorStr from "../../color/color";
import {Image, Title, Glass, Line} from "../../"
import FeaturedExamples from "../featured/featured";

import * as s from "./section.module.css";

export default function ServiceSection({ content }) {
  const { layout } = content;
  let Component;
  if (layout === "heading") {
    Component = TitleSection;
  } else {
    Component = ImageSection;
  }
  return <Component {...content} />
}

const TitleSection = ( content ) => {
  const { title, _rawContent, layout, featured } = content
  const [isEnter, setIsEnter] = useState(false);
  const ref = useRef();

  useEffect(() => {
    let trigger;
    trigger = ScrollTrigger.create({
      trigger: ref.current,
      start: () => window.innerWidth < 768 ? '0% 70%' : '0% 50%',
      end: () => window.innerWidth < 768 ? '0% 70%' : '0% 50%',
      once: true,
      onEnter: () => setIsEnter(true)
    });
    return () => { trigger && trigger.kill(); }
  }, [ref, setIsEnter])

  const color = content.color || "#0000ff";

  return (
    <section ref={ref} className={`${s.section} ${s[layout]}`}>
      <Glass className={s.glass} color={color} ready={isEnter} />
      <Title
        className={s.title}
        value={title}
        component={"h2"}
        isReady={isEnter}
        animationStep={100}
        animationDuration={300}
        color={getColorStr(color, 20, 90, 1)}
      />
      <Line className={s.line} isReady={isEnter} />
      <div className={s.content}>
        <PortableText value={_rawContent} />
        <FeaturedExamples value={featured} />
      </div>
    </section>
  )
}
const ImageSection = ( content ) => {
  const { title, _rawContent, image, _rawContent2, image2, layout, featured } = content
  const { background } = image.asset.metadata.palette.muted;
  const [isEnter, setIsEnter] = useState(false);
  const [isEnter2, setIsEnter2] = useState(false);
  const ref = useRef();
  const ref2 = useRef();

  useEffect(() => {
    let trigger, trigger2;
    trigger = ScrollTrigger.create({
      trigger: ref.current,
      start: () => window.innerWidth < 768 ? '0% 70%' : '0% 50%',
      end: () => window.innerWidth < 768 ? '0% 70%' : '0% 50%',
      once: true,
      onEnter: () => setIsEnter(true)
    });
    if (image2) {
      trigger2 = ScrollTrigger.create({
        trigger: ref2.current,
        start: () => window.innerWidth < 768 ? '0% 70%' : '0% 50%',
        end: () => window.innerWidth < 768 ? '0% 70%' : '0% 50%',
        once: true,
        onEnter: () => setIsEnter2(true)
      });
    }
    return () => {trigger && trigger.kill(); trigger2 && trigger2.kill();}
  }, [ref, setIsEnter, ref2, setIsEnter2, image2])

  const wideImageLayouts = ['aboveLeft', 'aboveRight', 'double'];
  const wideImage = wideImageLayouts.includes(layout);
  const sizesNarrow = `
    (min-width: 1920px) 1600px,
    (min-width: 1600px) 1200px,
    (min-width: 1280px) 800px,
    (min-width: 960px) 600px,
    320px
  `;
  const sizesWide = `
    (min-width: 1920px) 2400px,
    (min-width: 1280px) 1800px,
    (min-width: 960px) 1200px,
    (min-width: 768px) 900px,
    320px
  `;
  const width = wideImage ? 1200 : 800;
  const sizes = wideImage ? sizesWide : sizesNarrow;
  return (
    <section ref={ref} className={`${s.section} ${s[layout]}`}>
      <Image
        image = {image}
        className={s.image}
        isReady={isEnter}
        width={width}
        sizes={sizes}
        alt=""
      />
      <Glass className={s.glass} color={background} ready={isEnter} />
      <Title
        className={s.title}
        value={title}
        component={"h2"}
        isReady={isEnter}
        animationStep={100}
        animationDuration={300}
        color={getColorStr(background, 20, 90, 1)}
      />
      <Line className={s.line} isReady={isEnter} />
      <div className={s.content}>
        <PortableText value={_rawContent} />
        <FeaturedExamples value={featured} />
      </div>
      {layout === "double" && image2 && (
        <Image
          ref={ref2}
          image={image2}
          className={s.image2}
          isReady={isEnter2}
          width={800}
          sizes={sizesNarrow}
          alt=""
        />
      )}
      {layout === "double" && _rawContent2 && (
        <div className={s.content2}>
          <PortableText value={_rawContent2} />
        </div>
      )}
    </section>
  )
}