import React from 'react'
import { graphql } from 'gatsby' 
import {Layout} from '../components'
import {Hero, Section} from '../components/servicePage';

const ServicePage = ({data}) => {
  const ctx = data.sanityService || data.sanityPlatform
  const {title, poster, description, content, seoTitle, seoDescription} = ctx
  return (
  <Layout
    seo={{
      title: seoTitle,
      description: seoDescription
    }}
    headerTheme="dark"
    floatingHeader={true}
  >
    <Hero
      title={title}
      description={description}
      image={poster}
    />
    {content.map( v => <Section key={v._key} content={v} />)}
  </Layout>
)}

export const pageQuery = graphql`
  query ServiceBySlug(
    $slug: String
  ) {
    sanityService(slug: {current: { eq: $slug}}) {
      title
      subtitle
      poster {
        ...ImageWithPreview
      }
      description
      content {
        _key
        layout
        title
        color
        _rawContent(resolveReferences: {maxDepth: 10})
        _rawContent2(resolveReferences: {maxDepth: 10})
        image {
          ...ImageWithPreview
          asset {
            metadata {
              dimensions {
                aspectRatio
              }
              palette {
                muted {
                  background
                }
              }
            }
          }
          crop {
            bottom
            left
            right
            top
          }
        }
        image2 {
          ...ImageWithPreview
          asset {
            metadata {
              dimensions {
                aspectRatio
              }
              palette {
                muted {
                  background
                }
              }
            }
          }
          crop {
            bottom
            left
            right
            top
          }
        }
        featured
      }
      seoTitle
      seoDescription
    }
    sanityPlatform(slug: {current: { eq: $slug}}) {
      title
      subtitle
      poster {
        ...ImageWithPreview
      }
      description
      content {
        _key
        layout
        title
        _rawContent
        _rawContent2
        image {
          ...ImageWithPreview
          asset {
            metadata {
              dimensions {
                aspectRatio
              }
              palette {
                muted {
                  background
                }
              }
            }
          }
          crop {
            bottom
            left
            right
            top
          }
        }
        image2 {
          ...ImageWithPreview
          asset {
            metadata {
              dimensions {
                aspectRatio
              }
              palette {
                muted {
                  background
                }
              }
            }
          }
          crop {
            bottom
            left
            right
            top
          }
        }
        featured
      }
      seoTitle
      seoDescription
    }
  }
`

export default ServicePage