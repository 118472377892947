import React from "react";
import { PortableText as ReactPortableText } from "@portabletext/react";
import { Link } from "gatsby";

export default function PortableText( props ) {
  const components = {
    marks: {
      internalLink: InternalLink
    }
  } 
  return (<ReactPortableText {...props} components={components} /> )
}

function InternalLink({ children, value }) {
  const type = value.reference._type;
  const slug = value.reference.slug.current;
  const path = `${pathByType[type]}/${slug}`;
  console.log(path);
  return <Link to={path}>{children}</Link>
}

const pathByType = {
  service: "/services",
  platform: "/platforms"
}