import React, {useEffect, useLayoutEffect, useState, useRef} from "react";
import Image from "gatsby-plugin-sanity-image";
import {Title} from "../../";

import * as s from "./hero.module.css";

const ServiceHero = ({ title, description, image }) => {
  const ref = useRef();
  const [height, setHeight] = useState(0);
  const [isReady, setIsReady] = useState(false);
  
  const width = 1440;
  useLayoutEffect(() => {
    function handleResize() {
      const wide = Math.round(width / (16/9));
      const narrow = Math.round(width / (9/16));
      setHeight(window.innerWidth > window.innerHeight ? wide : narrow)
    };
    handleResize();
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  
  useEffect(() => {
    const onLoad = () => {
      setIsReady(true);
    }
    const el = ref.current.querySelector(`.${s.image}[src^="http"]`);
    if (!el) {return}
    if (el.complete) {
      onLoad();
      return;
    } else {
      el.addEventListener("load", onLoad, {once: true});
      return () => el.removeEventListener("load", onLoad);
    }
  }, [height, ref])

  return (
  <section ref={ref} className={`${s.section} ${isReady ? s.ready : ""}`}>
    {
      height && (
        <div className={s.imageWrapper}>
          <Image
            {...image}
            width={width}
            height={height}
            className={s.image}
            config={{
              quality: 80,
              fit: "crop"
            }}
            alt=""
          />
        </div>
      )
    }
    <div className={s.contentWrapper}>
      <Title value={title} className={`${s.title} ${s.desktopTitle}`} isReady={isReady} />
      {description && (
        <div className={s.descriptionWrapper}>
          <div className={s.descriptionAnimator}>
            <div className={s.description}>
              <Title value={title} className={`${s.title} ${s.mobileTitle}`} isReady={isReady} />
              <p>{description}</p>
              <svg className={s.arrow} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path fill='#FFF' d='m4.7 16.3 6.3 6.3v2.8l-7.7-7.7 1.4-1.4ZM17 2v24.6l10.3-10.3 1.4 1.4L15 31.4V2h2Z' /></svg>
            </div>
          </div>
        </div>
      )}
    </div>
  </section>
)}

export default ServiceHero